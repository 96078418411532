<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    
  >
    <template #default="{ hide }" style="width: 40rem !important;">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headerType}} {{ pageTitle }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >

          <!-- English Name -->
          <validation-provider
            #default="validationContext"
            name="English Name"
            rules="required"
          >
            <b-form-group
              label="English Name"
              label-for="english-name"
            >
              <b-form-input
                id="english-name"
                v-model="name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.name">{{ allerrors.name[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- Arabic Name -->
          <validation-provider
            #default="validationContext"
            name="Arabic Name"
            rules="required"
          >
            <b-form-group
              label="Arabic Name"
              label-for="arabic_name"
            >
              <b-form-input
                id="arabic_name"
                v-model="name_ar"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.name_ar">{{ allerrors.name_ar[0] }}</span>
            </b-form-group>
          </validation-provider>

           <!-- price -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required"
          >
            <b-form-group
              label="Price"
              label-for="price"
            >
              <b-form-input
                id="price"
                v-model="price"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.list_price">{{ allerrors.list_price[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- Machine types -->  
          <validation-provider
            #default="validationContext"
            name="Select Machine Type"
            rules="required"
          >
            <b-form-group
              label="Select Machine Type"
              label-for="machine-type"
              :state="getValidationState(validationContext)"
            >
               <v-select
                  id="machine-type"
                  v-model="machine_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="description"
                  :options="types"
                  :reduce="types => types.id"
                  @input="getServiceType"
                />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.product_type">{{ allerrors.product_type[0] }}</span>
            </b-form-group>
          </validation-provider>


          <!-- Prouct  types -->  
          <validation-provider
            #default="validationContext"
            name="Select Product type"
            rules="required"
          >
            <b-form-group
              label="Select Product Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
               <v-select
                  id="type"
                  v-model="service_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  value="id"
                  :options="specificServices"
                  :reduce="types => types.id"
                  
                />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.product_type">{{ allerrors.product_type[0] }}</span>
            </b-form-group>
          </validation-provider>


          <!-- fuel  types -->  
          <validation-provider
            #default="validationContext"
            name="check fuel type"
            rules="required"
          >
            <b-form-group
              label="fuel Type"
              label-for="fuel-type"
              :state="getValidationState(validationContext)"
            >
              <b-form-radio-group
                v-model="fuel_id"
                :options="fuelsTypes"
                class="demo-inline-spacing mb-1"
                value-field="id"
                text-field="name"
                disabled-field="disabled"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.fuel_id">{{ allerrors.fuel_id[0] }}</span>
            </b-form-group>
          </validation-provider>

         


           <!-- select company -->
          <validation-provider
            #default="validationContext"
            name="Select Company"
            rules="required"
          >
            <b-form-group
              label="Select Company"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
                
               <v-select
                  id="type"
                  v-model="company_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="allCompany"
                  :reduce="types => types.id"
                  select
                >
                <option value="0">amro</option>
                </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.company_id">{{ allerrors.company_id[0] }}</span>
            </b-form-group>
          </validation-provider>
          
          <!-- images -->
        <div class="d-flex flex-wrap">
          <!-- Upload Icon -->
          <!-- <validation-provider
            #default="validationContext"
            name="Icon"
            rules="required"
          > -->
           

          <div>
            <b-media class="mb-2">
              <template #aside >
                <b-avatar
                  v-if="imageUrl"
                  ref="previewEl"
                  :src="imageUrl"
                  :text="avatarText(name)"
                  size="90px"
                  rounded
                />
              </template>
              
              
            </b-media>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click="$refs.imageRef.click()"
              >
                <input
                  ref="imageRef"
                  type="file"
                  class="d-none"
                  @change="onImageChange"
                >
                <span class="d-none d-sm-inline">Upload image</span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
              <span class="text-danger text-sm" v-if="allerrors.products_image">{{ allerrors.products_image[0] }}</span>
            </div>
            <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>

          </validation-provider> -->
          </div>

          </div>
     
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }} 
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner,BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import DataService from './data/services'
import services from './data/services'
export default {
  
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    vSelect,
    BSpinner,
    BFormRadioGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    useInputImageRenderer,
    avatarText,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {},
    },
    parentCategories: {
      type: Array,
      default: () => [],
    },
    pageTitle: {
      type: String,
    },
    allCompany: {
      type: Array,
      required: true,
    },
    allCategory: {
      type: Array,
      required: true,
    },
    companyType: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      name:'',
      name_ar:'',
      type:'',
      parent_id: 0,
      products_image:'',
      products_image_img:'',
      // service_amount: 0,
      company_id:'',
      category_id:'',
      machine_type_id:'',
      price: 0,
      iconUrl: '',
      imageUrl: '',
      fuelsTypes: [],
      fuel_id: 1,
      service_id: '',
      id: '',
      type: '',
      loading: false,
      headerType: 'Add',
      production_date: '',
      manufacture_date: '',
      allerrors: [],
      brands: [],
      brand_id: '',
      machines: [],
      machine_id: '',
      specificServices: [],
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.dataSlider).length === 0) {
        this.headerType = 'Add'
        this.initValues()
      }else {        
        this.id       = this.dataSlider.id
        this.name     = this.dataSlider.name
        this.name_ar  = this.dataSlider.name_ar
        this.type     = this.dataSlider.type
        // this.service_amount     = this.dataSlider.service_amount
        this.price     = this.dataSlider.list_price
        this.category_id     = this.dataSlider.category_id
        this.company_id     = this.dataSlider.company_id
        this.imageUrl = this.dataSlider.products_image
        this.iconUrl  = this.dataSlider.products_icon
        this.products_image = this.dataSlider.products_image
        this.manufacture_date = this.dataSlider.manufacture_date
        this.machine_type_id = this.dataSlider.machine_type_id
        this.fuel_id = this.dataSlider.fuel_id
        this.service_id = this.dataSlider.service_id
        this.headerType = 'Edit'

        this.getServiceType(this.dataSlider.machine_id)
        // this.initValues()
      }
    }
  },
  methods: {
    initValues() {
      if(this.dataSlider.id) return
        this.id = null
        this.name = null
        this.name_ar = null
        this.type = 'car'
        this.products_image = null
        this.products_image_img = null
      
        this.imageUrl = null
        this.iconUrl = null
        this.price = null
        // this.service_amount = null
        this.company_id = null
        this.category_id = null
        this.machine_type_id = null
        this.service_id = null
        this.manufacture_date = null
        this.fuel_id = 1
        this.allerrors = {}
    },
    onIconChange(e) {
      const file = e.target.files[0];
      this.categories_icon = file
      this.iconUrl = URL.createObjectURL(file);
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.categories_image = file
      this.imageUrl = URL.createObjectURL(file);
    },
    onSubmit(){
      if(!this.id){
        this.sendData()
      }else{
        this.editData();
      }
    },
    prepareData(){
      const data = new FormData();
      data.append('name', this.name);
      data.append('name_ar', this.name_ar);
      data.append('type', this.type);
      data.append('company_id', this.company_id);
      data.append('category_id', this.category_id);
      data.append('machine_type_id', this.machine_type_id);
      data.append('list_price', this.price);
      // data.append('service_amount', this.service_amount);
      data.append('products_image', this.categories_image);
      data.append('fuel_id', this.fuel_id);
      data.append('service_id', this.service_id);
      (this.id) ? data.append('_method', 'put') : ''
      return data;
    },
    sendData(){
      this.loading = true;
        DataService.create(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$parent.refreshData(response.data.data);            
          }
          else if(responseCode === 101){
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    editData(){
      this.loading = true;
        DataService.update(this.id, this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$parent.refreshData(response.data.data);     
          }
          else if(responseCode === 101){
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    prepareOption(){
      const newFirstElement = {id: 0, name: 'is Parent'}
      return  [newFirstElement].concat(this.parentCategories)   
    },
    getbrandLkb(){
      DataService.getbrandLkb()
      .then(response => {
        this.brands = response.data
      });
    },

    getFuelType(){
      DataService.getFuelType()
      .then(response => {
        this.fuelsTypes = response.data.data
      })
    },  
    setMachine(value){
      console.log(value);
    },
    getServiceType(val){
      this.specificServices = []
      let services = this.getServices
      Object.entries(services).forEach(([key, value]) => {
        if(this.machine_type_id == value.machine_type_id){
          this.specificServices.push(value)
        }
      });
      
      // changa fuel to gasoline if car type is generater
      this.fuel_id =  (val == 2) ? 2 : 1
    }
    

  },
  mounted() {
    this.getbrandLkb()
    this.getFuelType()
  },
  computed: {
    types (){
      return this.$store.getters['admin/GetMachineTypes'] 
    },
    getServices() {
      return this.$store.getters['admin/GetServices'] 
    }
  },
  
  setup(props, { emit }) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.categories_image_img = base64
    })


    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.b-sidebar.sidebar-lg{
  widows: 40rem !important;
}
</style>
